<template>
  <div class="pl-8 pr-8">
    <v-row class="mt-11">
      <v-col class="text-left" cols="12">
        <h1 class="document-managmet___title">Bono / documentos</h1>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="12">
        <div class="mb-xl-margin-file">
          <v-card elevation="0" class="px-15 py-15" style="border-radius: 20px">
            <!-- DOCUMENTO -->
            <template v-if="show == 1">
              <v-row>
                <v-col cols="12">
                  <p class="document-managmet___subtitle pb-2">
                    Nombre del documento *
                  </p>
                  <v-form ref="formD">
                    <v-text-field
                      prepend-inner-icon="mdi-file-outline"
                      color="#466BE3"
                      v-model="formDocument.nombre"
                      :rules="[(v) => !!v || 'Este campo es requerido']"
                      label="Ingrese el nombre del documento"
                    >
                    </v-text-field>
                  </v-form>
                </v-col>
                <v-col cols="12" class="pb-6">
                  <p class="document-managmet___subtitle pb-4">Seleccione</p>
                  <v-switch
                    v-model="formDocument.check_value"
                    label="Incluir campo valor"
                    color="#466be3"
                    value="true"
                    hide-details
                  ></v-switch>
                  <v-switch
                    class="pt-10"
                    v-model="formDocument.send_pbot"
                    label="Enviar documento PBOT"
                    color="#466be3"
                    value="true"
                    hide-details
                  ></v-switch>
                  <v-switch
                    class="pt-10"
                    v-model="formDocument.send_duva"
                    label="Enviar copia por correo electrónico"
                    color="#466be3"
                    value="true"
                    hide-details
                  ></v-switch>
                  <v-switch
                    class="pt-10"
                    v-model="formDocument.coordinator_signature"
                    label="Agregar firma de Gerente de Relaciones Laborales"
                    color="#466be3"
                    value="true"
                    hide-details
                  ></v-switch>
                </v-col>

                <v-col cols="6">
                  <p class="document-managmet___subtitle pb-4 pt-5">
                    Diligencie el documento con las variables requeridas
                  </p>
                </v-col>

                <v-col cols="6" class="pt-7">
                  <v-slide-group multiple show-arrows>
                    <v-slide-item
                      v-for="n in listField"
                      :key="n"
                      v-slot="{ active, toggle }"
                    >
                      <p
                        class="document-managmet___field mb-0"
                        :id="'field-' + n"
                        @click="copy(n)"
                      >
                        {{ n }}
                      </p>
                    </v-slide-item>
                  </v-slide-group>
                </v-col>

                <v-col cols="12" class="pb-6">
                  <p class="document-managmet___subtitle pb-4">
                    Seleccione una plantilla
                  </p>
                  <v-slide-group
                    multiple
                    show-arrows
                    class="my-4"
                    prev-icon="mdi-chevron-left-circle-outline"
                    next-icon="mdi-chevron-right-circle-outline"
                  >
                    <v-slide-item
                      class="my-0 py-2"
                      v-for="data in listTemplate"
                      :key="data.id"
                      v-slot="{ active, toggle }"
                    >
                      <v-card
                        v-bind="attrs"
                        v-on="on"
                        :input-value="active"
                        class="pt-2 pb-1 mx-3 card-template-d text-center"
                        @click="updateTemplate(data), toggle"
                        style="border-radius: 15px"
                        width="200"
                        outlined
                        elevation="0"
                      >
                        <v-card-title class="py-0 my-0">
                          <v-btn
                            @click="deleteTemplate(data)"
                            color="red"
                            icon
                            small
                            style="display: flex; left: 150px; bottom: 0px"
                          >
                            <v-icon> mdi-delete </v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-tooltip max-width="300" color="#000" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-card-text
                              v-bind="attrs"
                              v-on="on"
                              class="pt-0 pb-1"
                            >
                              <img
                                class="text-center"
                                :src="origin + '/icon/service-2.svg'"
                                width="60"
                              />
                              <v-layout justify-center>
                                <p class="document-managmet___subtitle mt-3">
                                  {{ data.name }}
                                </p>
                              </v-layout>
                            </v-card-text>
                          </template>
                          <span> {{ data.description }}</span>
                        </v-tooltip>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
                  <v-alert type="info" text v-if="listTemplate.length == 0">
                    No se encontraron plantillas
                    <v-btn
                      @click="openTemplate = true"
                      color="info"
                      dark
                      rounded
                      small
                      class="ml-4"
                      ><strong> Agregar nueva plantilla </strong></v-btn
                    >
                  </v-alert>
                  <v-layout justify-end>
                    <v-btn
                      v-if="formDocument.html != ''"
                      @click="openTemplate = true"
                      class="mb-1 mt-4 mr-4"
                      color="#466BE3"
                      rounded
                      dark
                      width="260"
                    >
                      <strong> Guardar nueva plantilla </strong>
                    </v-btn>
                  </v-layout>
                </v-col>

                <v-col cols="12">
                  <vue-editor
                    ref="editor"
                    v-model="formDocument.html"
                    :editor-toolbar="customToolbar"
                  ></vue-editor>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="6">
                  <v-btn
                    @click="previewFile()"
                    style="background-color: #fff"
                    block
                    rounded
                    outlined
                    color="#466BE3"
                  >
                    anterior
                  </v-btn>
                </v-col>

                <v-col cols="6">
                  <v-btn
                    @click="nextForm()"
                    block
                    rounded
                    color="#466be3"
                    dark
                    class="document-manager__button"
                  >
                    Siguiente
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <!-- DATA -->
            <template v-if="show == 2">
              <v-row justify="center">
                <v-col>
                  <p class="document-managmet___subtitle pb-4">
                    Seleccione las cédulas a las cuales va a asignar el
                    documento con su respectivo monto &nbsp;&nbsp;
                    <v-btn
                      rounded
                      dark
                      color="#466be3"
                      @click="openImport = true"
                    >
                      IMPORTACIÓN MASIVA
                    </v-btn>
                  </p>

                  <v-form ref="form">
                    <v-autocomplete
                      prepend-inner-icon="mdi-account-outline"
                      multiple
                      color="#466be3"
                      v-model="listSelectedUsers"
                      :items="listUsers"
                      item-text="user"
                      return-object
                      label="Nombre - número de cédula *"
                      :rules="[(v) => !!v || 'Este campo es requerido']"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          dark
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          color="#466be3"
                        >
                          {{ data.item.user }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-form>

                  <p class="document-managmet___subtitle pt-4 pb-4">
                    Usuarios asignados
                  </p>
                  <v-divider></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="item in listSelectedUsers" :key="item.name">
                          <td class="text-left">
                            <div class="float-left mt-3 pr-2">
                              <v-btn
                                class="mb-6 mr-2"
                                icon
                                fab
                                x-small
                                @click="remove(item)"
                              >
                                <v-icon small> mdi-close </v-icon>
                              </v-btn>
                              <img
                                style="
                                  border-radius: 120px;
                                  border: 1.3px solid #466be3;
                                "
                                width="40"
                                height="40"
                                :src="item.img"
                              />
                            </div>
                            <div class="mt-3">
                              <p
                                class="mb-0 mt-2 text-1-1-lines document-managmet___txt-dark"
                              >
                                <strong> {{ item.full_name }} </strong>
                              </p>
                              <p
                                class="mb-0 mb-2 text-1-1-lines document-managmet___txt"
                              >
                                {{ item.profile.name }}
                              </p>
                            </div>
                          </td>
                          <td
                            v-if="formDocument.check_value == 'true'"
                            class="text-left"
                          >
                            <v-text-field
                              class="mb-2"
                              prepend-inner-icon="mdi-currency-usd"
                              type="number"
                              label="Asignar monto"
                              v-model="item.value"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-tooltip max-width="300" color="#000" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  fab
                                  icon
                                  @click="preview(item)"
                                  color="#466be3"
                                >
                                  <v-icon> mdi-eye-outline </v-icon>
                                </v-btn>
                              </template>
                              <span> Previsualizar documento</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <!-- <v-col cols="12">
                  <v-btn
                    @click="preview()"
                    class="document-managmet___btn-preview"
                    text
                    color="#466be3"
                  >
                    <v-icon color="#466be3"> mdi-eye-outline </v-icon> &nbsp;
                    previsualización
                  </v-btn>
                </v-col> -->
                <v-col cols="6">
                  <v-btn
                    @click="previewFile()"
                    style="background-color: #fff"
                    block
                    rounded
                    outlined
                    color="#466BE3"
                  >
                    anterior
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    @click="confirm()"
                    block
                    rounded
                    color="#466be3"
                    dark
                    class="document-manager__button"
                  >
                    Siguiente
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-snackbar outlined color="#466BE3" v-model="alertCopy" timeout="5000">
      <strong style="font-size: 16px"> ¡Se copió el atributo! </strong>
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="alertCopy = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="openConfirm" width="410" persistent>
      <v-card style="border-radius: 15px">
        <v-card-title class="pt-6">
          <v-layout justify-center>
            <img
              class="ml-6"
              width="80"
              height="80"
              src="../../assets/icon/alert.svg"
            />
          </v-layout>
          <v-btn
            @click="openConfirm = false"
            color="#466BE3"
            icon
            x-small
            style="display: flex; left: 18px; bottom: 45px"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="document-managmet___txt-dialog-title">¿Esta seguro</p>
          <p class="document-managmet___txt-dialog-sms pb-0 mb-0">
            que desea cargar el archivo?
          </p>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-row>
            <v-col cols="6">
              <v-btn
                block
                color="#466BE3"
                dark
                rounded
                @click="openConfirm = false"
                outlined
              >
                CERRAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                color="#466BE3"
                dark
                rounded
                :loading="loadingBtn"
                @click="createUsersDocumentsToSign()"
              >
                enviar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openTemplate" width="600">
      <v-card style="border-radius: 15px">
        <v-card-title class="pt-6">
          <v-btn
            @click="openTemplate = false"
            color="#466BE3"
            icon
            x-small
            style="display: flex; left: 530px; bottom: 0px"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="document-managmet___txt-dialog-sms pb-2 mb-0">
            CREAR PLANTILLA
          </p>
          <p
            v-if="isTemplate"
            class="document-managmet___txt-dialog-sms pb-2 mb-0 mt-2"
          >
            Tiene un nuevo cambio en la plantilla
          </p>
          <p
            v-if="isTemplate"
            class="document-managmet___txt-dialog-sms pb-2 mb-0"
          >
            ¿Desea guardarlo?
          </p>
          <v-form ref="formD">
            <v-text-field
              v-model="formTemplate.name"
              label="Nombre de la plantilla"
            ></v-text-field>
            <v-textarea
              v-model="formTemplate.description"
              label="Descripción de la plantilla"
              rows="4"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-row>
            <v-col v-if="isTemplate" cols="6">
              <v-btn
                block
                color="#466BE3"
                dark
                rounded
                @click="saveDocument(), (openTemplate = false)"
                outlined
              >
                Continuar sin guardar
              </v-btn>
            </v-col>
            <v-col v-else cols="6">
              <v-btn
                block
                color="#466BE3"
                dark
                rounded
                @click="openTemplate = false"
                outlined
              >
                CERRAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                color="#466BE3"
                dark
                rounded
                :loading="loadingBtn"
                :disabled="loadingAll"
                @click="saveTemplate()"
              >
                <strong> Guardar plantilla </strong>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openImport" width="600" persistent>
      <v-card style="border-radius: 15px">
        <v-card-text class="py-10">
          <p class="document-managmet___txt-dialog-title">
            Adjuntar archivo de importación
          </p>
          <p class="pb-0 mb-2 text-left">
            El archivo debe contar con lo siguiente:
          </p>
          <p class="pb-0 mb-2 text-left">
            1. una columna para la cédula, esta puede tener como título las
            siguientes variables: <strong> cc, cedula, documento </strong>.
          </p>
          <p class="pb-0 mb-2 text-left">
            2. una columna para el valor a pagar, esta puede tener como título
            las siguientes variables:
            <strong>valor, monto, total, pago</strong> .
          </p>
          <p class="pb-0 mb-2 text-left">
            3. Recuerde que en los dos puntos anteriores las variables no deben
            tener tildes, ni caracteres especiales.
          </p>
          <p class="pb-0 mb-2 text-left">
            3. Debe ser un archivo tipo excel (xlsx) que no supere 1MB de peso.
          </p>

          <v-file-input
            class="mt-8"
            v-model="file"
            color="#466BE3"
            counter
            label="Adjuntar archivo"
            prepend-icon="mdi-paperclip"
            outlined
            :show-size="1000"
          >
            <template v-slot:selection="{ text }">
              <v-chip color="#466BE3" dark label small>
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-row>
            <v-col cols="6">
              <v-btn
                block
                color="#466BE3"
                dark
                rounded
                @click="openImport = false"
                outlined
              >
                CERRAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                color="#466BE3"
                dark
                rounded
                :loading="loadingBtn"
                @click="importData()"
              >
                ADJUNTAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert
      :open="message.dialog"
      :text="message.txt"
      :title="message.title"
      :type="message.type"
      @close="message.dialog = false"
      :redirect="message.redirect"
    >
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";

export default {
  components: {
    VueEditor,
    Alert,
  },
  data() {
    return {
      origin: window.location.origin,
      show: 1,
      loadingAll: false,
      loadingBtn: false,
      openConfirm: false,
      openImport: false,
      alertCopy: false,
      file: null,
      formDocument: {
        document_id: null,
        nombre: "",
        document_type: "gerentes",
        document: "custom",
        coordinator_signature: null,
        send_duva: null,
        send_pbot: null,
        check_value: null,
        html: "",
      },
      message: {
        dialog: false,
        title: "",
        txt: "",
        type: "",
        redirect: "",
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
      ],
      listUsers: [],
      listSelectedUsers: [],
      listField: [],
      documentId: null,
      token: "",
      name: "",
      infoToken: {
        document: "",
      },
      currentInfoDoc: null,
      htmlInit: "",
      listTemplate: [],
      formTemplate: {
        html: "",
        type: "gerentes",
        name: "",
        description: "",
      },
      openTemplate: false,
      isTemplate: false,
      newHtml: "",
    };
  },
  methods: {
    deleteTemplate(data) {
      this.loadingAll = true;
      Api.DocumentTemplate()
        .deleteTemplate(this.token, data.id)
        .then((res) => {
          if (res.data.cod == 0) {
            this.message.dialog = true;
            this.message.title = "¡Excelente!";
            this.message.txt = res.data.message;
            this.message.type = "success";
            this.message.redirect = "";
            this.getTemplate();
          }
        })
        .catch((error) => {
          console.log(error);
          this.message.dialog = true;
          this.message.title = "¡Oh no!";
          this.message.txt = error.response.data.message;
          this.message.type = "error";
          this.message.redirect = "";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    saveTemplate() {
      this.loadingAll = true;
      this.formTemplate.html = this.formDocument.html;
      Api.DocumentTemplate()
        .saveDocumentTemplate(this.token, this.formTemplate)
        .then((res) => {
          if (res.data.cod == 0) {
            if (this.isTemplate) {
              console.log(
                "Se guardo la plantilla, se va a guardar el template"
              );
              this.saveDocument();
            }
            this.getTemplate();
            this.openTemplate = false;
            this.message.dialog = true;
            this.message.title = "¡Excelente!";
            this.message.txt = res.data.message;
            this.message.type = "success";
            this.message.redirect = "";
          }
        })
        .catch((error) => {
          console.log(error);
          this.message.dialog = true;
          this.message.title = "¡Oh no!";
          this.message.txt = error.response.data.message;
          this.message.type = "error";
          this.message.redirect = "";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    getTemplate() {
      this.loadingAll = true;
      Api.DocumentTemplate()
        .getDocumentTemplate(this.token, "gerentes")
        .then((res) => {
          if (res.data.cod == 0) {
            this.listTemplate = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    updateTemplate(data) {
      this.htmlInit = this.formDocument.html;
      this.formDocument.html = data.html;
      this.newHtml = data.html;
      this.isTemplate = true;
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    copy(n) {
      var aux = document.createElement("textarea");
      aux.innerHTML = document.getElementById("field-" + n).innerHTML;
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.alertCopy = true;
    },
    previewFile() {
      if (this.show == 1) {
        this.$router.push({ name: "marginCommisions" });
      } else {
        this.show = 1;
      }
    },
    nextForm() {
      if (this.isTemplate && this.formDocument.html != this.newHtml) {
        this.openTemplate = true;
      } else {
        console.log("siguiente");
        this.saveDocument();
      }
    },
    remove(data) {
      var pos = null;
      for (let i = 0; i < this.listSelectedUsers.length; i++) {
        if (this.listSelectedUsers[i].document_number == data.document_number) {
          pos = i;
        }
      }
      this.listSelectedUsers.splice(pos, 1);
    },
    getUsers() {
      this.loadingAll = true;
      Api.Auth()
        .getUsers()
        .then((res) => {
          if (res.data.cod == 0) {
            this.listUsers = res.data.data;
            for (let i = 0; i < this.listUsers.length; i++) {
              this.listUsers[i].user =
                this.toTitleCase(this.listUsers[i].full_name) +
                " - " +
                this.listUsers[i].document_number;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    getFieldsCurrent() {
      this.loadingAll = true;
      Api.Auth()
        .currentUser(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            var lField = Object.keys(res.data.data);
            this.listField = lField.filter(
              (val) => val != "id" && val != "isAdmin"
            );
            for (let i = 0; i < this.listField.length; i++) {
              this.listField[i] = "{{" + this.listField[i] + "}}";
            }
            this.listField.unshift("{{value}}");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    saveDocument() {
      if (this.formDocument.html == "" || this.formDocument.html == null) {
        console.log("***");
        this.message.dialog = true;
        this.message.title = "¡Oh no!";
        this.message.txt = "El documento es obligatorio";
        this.message.type = "error";
        this.message.redirect = "";
        return false;
      }

      // if( this.currentInfoDoc != null && (this.currentInfoDoc.send_duva == this.formDocument.send_duva)) {
      //   this.show = 2;
      //   return false;
      // } validar que si no se hizo ningún cambio, pase de una vez al paso 2

      if (this.$refs.formD.validate()) {
        this.loadingAll = true;
        var data = this.formDocument;
        data.check_value = data.check_value == null ? false : true;
        data.send_duva = data.send_duva == null ? false : true;
        data.coordinator_signature =
          data.coordinator_signature == null ? false : true;
        data.send_pbot = data.send_pbot == null ? false : true;

        // console.log(this.formDocument);
        Api.Auth()
          .createDocumentToSign(this.token, this.formDocument)
          .then((res) => {
            if (res.data.cod == 0) {
              this.documentId = res.data.data.id;
              this.show = 2;
              this.message.dialog = true;
              this.message.title = "Excelente";
              this.message.txt = res.data.message;
              this.message.type = "success";
              this.message.redirect = "";

              this.formDocument.send_duva = res.data.data.send_duva
                ? "true"
                : null;
              this.formDocument.check_value = res.data.data.check_value
                ? "true"
                : null;
              this.formDocument.send_pbot = res.data.data.send_pbot
                ? "true"
                : null;
              this.formDocument.coordinator_signature = res.data.data
                .coordinator_signature
                ? "true"
                : null;
            } else {
              this.message.dialog = true;
              this.message.title = "¡Oh no!";
              this.message.txt = res.data.message;
              this.message.type = "error";
              this.message.redirect = "";
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loadingAll = false;
          });
      }
    },
    confirm() {
      if (this.listSelectedUsers.length == 0) {
        this.message.dialog = true;
        this.message.title = "¡Oh no!";
        this.message.txt = "Debe asignar por lo menos 1 usuario";
        this.message.type = "error";
        this.message.redirect = "";
      } else {
        this.openConfirm = true;
      }
    },
    createUsersDocumentsToSign() {
      this.loadingBtn = true;
      var users = [];
      for (let i = 0; i < this.listSelectedUsers.length; i++) {
        if (this.formDocument.check_value == "true") {
          users.push({
            documentNumber: this.listSelectedUsers[i].document_number,
            value: this.listSelectedUsers[i].value,
          });
        } else {
          users.push({
            documentNumber: this.listSelectedUsers[i].document_number,
          });
        }
      }
      var data = {
        documentSignId: this.documentId,
        people: users,
      };
      Api.Auth()
        .createUsersDocumentsToSign(this.token, data)
        .then((res) => {
          if (res.data.cod == 0) {
            this.getDocumentSign(res.data.message);
          } else {
            this.message.dialog = true;
            this.message.title = "¡Oh no!";
            this.message.txt = res.data.message;
            this.message.type = "error";
            this.message.redirect = "";
          }
        })
        .catch((error) => {
          console.log(error);
          this.message.dialog = true;
          this.message.title = "¡Oh no!";
          this.message.txt = "Intenta más tarde...";
          this.message.type = "error";
          this.message.redirect = "";
        })
        .finally(() => {
          this.loadingBtn = false;
        });
    },
    pendingCreateDocument() {
      this.loadingAll = true;
      Api.Auth()
        .pendingCreateDocument(this.token, "gerentes")
        .then((res) => {
          if (res.data.cod == 0) {
            this.currentInfoDoc = res.data.data;
            this.documentId = res.data.data.id;
            this.formDocument.document_id = res.data.data.id;
            this.formDocument.nombre = res.data.data.nombre;
            this.formDocument.html = res.data.data.html;
            this.formDocument.check_value = res.data.data.check_value
              ? "true"
              : null;
            this.formDocument.send_duva = res.data.data.send_duva
              ? "true"
              : null;
            this.formDocument.send_pbot = res.data.data.send_pbot
              ? "true"
              : null;
            this.formDocument.coordinator_signature = res.data.data
              .coordinator_signature
              ? "true"
              : null;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    preview(data) {
      this.loadingAll = true;
      var data = {
        documentId: this.documentId,
        userId: data.id,
        value: data.value
      };
      Api.Auth()
        .previewDocument(this.token, data)
        .then((res) => {
          console.log(res);
          if (res.data.cod == 0) {
            const link = document.createElement("a");
            link.href = res.data.data;
            link.target = "_blank";
            link.download =
              "documentos-brm-" +
              new Date().toISOString().substr(0, 10) +
              ".pdf";
            link.click();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    getDocumentSign(message) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };
      let documents = {
        completed: null,
        pending: null,
      };
      Api.Auth()
        .signCompleted(config)
        .then((res) => {
          if (res.data.cod == 0) {
            documents.completed = res.data.data;
            this.openConfirm = false;
            this.message.dialog = true;
            this.message.title = "Excelente";
            this.message.txt = message;
            this.message.type = "success";
            this.message.redirect = "marginCommisions";
            this.$store.dispatch("updateDocuments", documents);
            Api.Auth()
              .signPending(config)
              .then((res) => {
                if (res.data.cod == 0) {
                  documents.pending = res.data.data;
                }
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    importData() {
      var dataForm = new FormData();
      var info = "sin_valor";
      if (this.formDocument.check_value) {
        info = "valor";
      }
      dataForm.append("check_value", info);
      dataForm.append("file", this.file);
      this.loadingBtn = true;
      Api.noAuth()
        .importUserBonus(dataForm, this.token)
        .then((res) => {
          this.loadingBtn = false;
          if (res.data.cod == 0) {
            this.openImport = false;
            this.message.dialog = true;
            this.message.title = "Excelente";
            this.message.txt = res.data.message;
            this.message.type = "success";
            this.message.redirect = "";
            var list = res.data.data;
            var data = {
              campaign: null,
              cc: null,
              cod_scc: null,
              document_number: null,
              full_name: null,
              headquarters_city: null,
              id: null,
              img: null,
              nickname: null,
              personal_email: null,
              profile: {
                id: null,
                name: null,
              },
              status: {
                id: null,
                name: null,
              },
              status_id: null,
              user: null,
              value: null,
            };
            this.listSelectedUsers = [];
            for (let i = 0; i < list.length; i++) {
              var data = {
                campaign: list[i].campaign,
                cc: list[i].cc,
                cod_scc: list[i].cod_scc,
                document_number: list[i].document_number,
                full_name: list[i].full_name,
                headquarters_city: list[i].headquarters_city,
                id: list[i].id,
                img: list[i].img,
                nickname: list[i].nickname,
                personal_email: list[i].personal_email,
                phone: list[i].phone,
                profile: {
                  id: list[i].profile.id,
                  name: list[i].profile.name,
                },
                status: {
                  id: list[i].status.id,
                  name: list[i].status.name,
                },
                status_id: list[i].status_id,
                user:
                  this.toTitleCase(list[i].full_name) +
                  " - " +
                  list[i].document_number,
                value: list[i].value,
              };
              this.listSelectedUsers.push(data);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loadingBtn = false;
        });
    },
    toTitleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
  },
  created() {
    this.getToken();
  },
  mounted() {
    this.getUsers();
    this.getFieldsCurrent();
    this.pendingCreateDocument();
    this.getTemplate();
  },
};
</script>
<style>
@import "./../../assets/css/main.less";
.mdi-chevron-right-circle-outline::before {
  /* content: "\F0B2A"; */
  color: #466be3;
}

.mdi-chevron-left-circle-outline::before {
  /* content: "\F0B28"; */
  color: #466be3;
}
.card-template-d:hover {
  background-color: #cfdaff;
  color: #fff;
}
.document-managmet___subtitle {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  color: #353535;
}
.document-managmet___txt-dark {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #353535;
}
.document-managmet___txt {
  font-family: "RobotoRegular";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  color: #626262;
}
.document-managmet___field {
  background-color: #07ba56;
  color: #ffffff;
  border-radius: 100px;
  padding: 2px 15px 2px 15px;
  margin-right: 18px;
  cursor: pointer;
}
.document-managmet___title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}
.document-managmet___txt-dialog-title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #353535;
}
.document-managmet___txt-dialog-sms {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #898989;
}
.document-managmet___btn-preview {
  width: 100%;
  border-bottom: 1.5px solid #466be3;
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
}

.document-manager__button:hover {
  background-color: #324ca1 !important;
}
</style>
